"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/b9-web-exchange/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetUserInfo = apiGetUserInfo;
exports.bindEmail = bindEmail;
exports.bindGoogleVerify = bindGoogleVerify;
exports.bindMobile = bindMobile;
exports.bingGoogleVerifyStatus = bingGoogleVerifyStatus;
exports.checkIp = checkIp;
exports.closeGoogleVerify = closeGoogleVerify;
exports.getGoogleSecret = getGoogleSecret;
exports.loginSendEmailCode = loginSendEmailCode;
exports.loginSendSmsCode = loginSendSmsCode;
exports.openGoogleVerify = openGoogleVerify;
exports.sendEmailCode = sendEmailCode;
exports.sendForgetEmailCode = sendForgetEmailCode;
exports.sendForgetSmsCode = sendForgetSmsCode;
exports.sendSignEmailCode = sendSignEmailCode;
exports.sendSignEmailCodeGeet = sendSignEmailCodeGeet;
exports.sendSignSmsCode = sendSignSmsCode;
exports.sendSignSmsCodeGeet = sendSignSmsCodeGeet;
exports.sendSmsCode = sendSmsCode;
exports.undataMobile = undataMobile;
exports.updateGoogleVerify = updateGoogleVerify;
exports.updatePassword = updatePassword;

var _api = _interopRequireDefault(require("@/assets/js/api"));

var _stationSetting = require("@/assets/js/stationSetting");

// const { VUE_APP_UCENTER } = process.env;
function apiGetUserInfo() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Users/getUserInfo"),
    data: data
  });
} //获取手机验证码 (非登录态)


function sendSignSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Account/sendSignSmsCode"),
    data: data
  });
} //获取手机验证码 (非登录态-极验)


function sendSignSmsCodeGeet() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/Register/mobileCode"),
    data: data
  });
} //找回密码，发送短信


function sendForgetSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/Forget/sms"),
    data: data
  });
} // // 获取手机验证码（二次校验）
// export function loginSendSmsCode(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/sendSmsCode`,
//     data,
//   });
// }
//获取手机验证码（二次校验）


function loginSendSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/LoginVerify/mobileCode"),
    data: data
  });
} // 获取手机验证码（完全登录态）


function sendSmsCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Users/sendSmsCode"),
    data: data
  });
} // 获取邮箱验证码（非登录态）


function sendSignEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Account/sendSignEmailCode"),
    data: data
  });
} // 获取邮箱验证码（非登录态-极验）


function sendSignEmailCodeGeet() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/Register/emailCode"),
    data: data
  });
} // 获取邮箱验证码（非登录态-极验）


function sendForgetEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/Forget/email"),
    data: data
  });
} // // 获取邮箱验证码（二次校验）
// export function loginSendEmailCode(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/sendEmailCode`,
//     data,
//   });
// }
// 获取邮箱验证码（二次校验）


function loginSendEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/V3/UCenter/LoginVerify/emailCode"),
    data: data
  });
} // 获取邮箱验证码(完全登录态)


function sendEmailCode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Users/sendEmailCode"),
    data: data
  });
} // 修改密码


function updatePassword() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/updatePassword"),
    data: data
  });
} // 绑定手机


function bindMobile() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/bindMobile"),
    data: data
  });
} // 绑定邮箱


function bindEmail() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/bindEmail"),
    data: data
  });
} // 修改绑定的手机


function undataMobile() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/updateMobile"),
    data: data
  });
} // 获取谷歌令牌


function getGoogleSecret() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/getGoogleSecret"),
    data: data
  });
} // 绑定谷歌令牌


function bindGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/bindGoogleVerify"),
    data: data
  });
} // 修改谷歌令牌


function updateGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/updateGoogleVerify"),
    data: data
  });
} // 关闭谷歌认证


function closeGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/closeGoogleVerify"),
    data: data
  });
} // 开启谷歌认证


function openGoogleVerify() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/openGoogleVerify"),
    data: data
  });
} // 获取谷歌绑定状态


function bingGoogleVerifyStatus() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/UCenter/Security/bingGoogleVerifyStatus"),
    data: data
  });
} // 根据IP区分国内国外


function checkIp() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _api.default)({
    url: "".concat(_stationSetting.VUE_APP_UCENTER, "/Version/checkIp"),
    data: data
  });
}